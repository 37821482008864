<template>
  <v-card class="pt-16 pb-7 text-center">
    <v-card-title class="justify-center">{{ title }}</v-card-title>
    <v-card-text class="mt-1">
      <slot></slot>
    </v-card-text>
    <v-card-actions class="d-block">
      <v-btn
        @click="confirm"
        width="180"
        large
        block
        text
        class="primary white--text body-1"
        >{{ btnText }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "TBasicDialog",
  props: {
    title: {
      type: String,
      required: true,
    },
    btnText: {
      type: String,
      required: true,
    },
  },
  methods: {
    confirm() {
      this.$emit("confirm");
    },
  },
};
</script>

<style></style>
